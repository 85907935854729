<template>
    <div class="w-6">
        <span class="grid grid-cols-6" v-if="classe == 1"
            v-tooltip.bottom-start="{ 'content': message + '<span class=\'font-medium\'>' + __('Benign') + '</span>', 'theme': 'my-tooltip', 'html': true }">
            <DiceOneFilledIcon class="fill-green-500 w-4 h-4 col-span-4"></DiceOneFilledIcon>
            <sup class="-translate-y-1 -translate-x-1">
                <Badge v-if="type != ''" color="green" size="2xs">{{ badgeLetter }}</Badge>
            </sup>
        </span>
        <span class="grid grid-cols-6" v-if="classe == 2"
            v-tooltip.bottom-start="{ 'content': message + '<span class=\'font-medium\'>' + __('Likely Benign') + '</span>', 'theme': 'my-tooltip', 'html': true }">
            <DiceTwoFilledIcon class="fill-green-700 w-4 h-4 col-span-4"></DiceTwoFilledIcon>
            <sup class="-translate-y-1 -translate-x-1">
                <Badge v-if="type != ''" color="green" size="2xs">{{ badgeLetter }}</Badge>
            </sup>
        </span>
        <span class="grid grid-cols-6" v-if="classe == 3"
            v-tooltip.bottom-start="{ 'content': message + '<span class=\'font-medium\'>' + __('Uncertain significance') + '</span>', 'theme': 'my-tooltip', 'html': true }">
            <DiceThreeFilledIcon class="fill-gray-500 w-4 h-4 col-span-4"></DiceThreeFilledIcon>
            <sup class="-translate-y-1 -translate-x-1">
                <Badge v-if="type != ''" color="gray" size="2xs">{{ badgeLetter }}</Badge>
            </sup>
        </span>
        <span class="grid grid-cols-6" v-if="classe == 4"
            v-tooltip.bottom-start="{ 'content': message + '<span class=\'font-medium\'>' + __('Likely Pathogenic') + '</span>', 'theme': 'my-tooltip', 'html': true }">
            <DiceFourFilledIcon class="fill-orange-400 w-4 h-4 col-span-4"></DiceFourFilledIcon>
            <sup class="-translate-y-1 -translate-x-1">
                <Badge v-if="type != ''" color="orange" size="2xs">{{ badgeLetter }}</Badge>
            </sup>
        </span>
        <span class="grid grid-cols-6" v-if="classe == 5"
            v-tooltip.bottom-start="{ 'content': message + '<span class=\'font-medium\'>' + __('Pathogenic') + '</span>', 'theme': 'my-tooltip', 'html': true }">
            <DiceFiveFilledIcon class="fill-red-600 w-4 h-4 col-span-4"></DiceFiveFilledIcon>
            <sup class="-translate-y-1 -translate-x-1">
                <Badge v-if="type != ''" color="red" size="2xs" class="">{{ badgeLetter }}</Badge>
            </sup>
        </span>
    </div>
</template>

<script setup>
import { DiceOneFilledIcon, DiceTwoFilledIcon, DiceThreeFilledIcon, DiceFourFilledIcon, DiceFiveFilledIcon } from "@/Components/Icons";
import { computed } from 'vue';
import Badge from "@/Components/Badge.vue";
import translate from "@/Composables/baseVue";

const props = defineProps({
    classe: Number,
    type: String,
    vartype: {
        type: String,
        default: "SNV"
    },
    score: Number,
    count: { type: Number, default: 1 },
    tx: { type: Number, default: 1 }
});

let badgeLetter = computed(() => {
    if (props.type == "auto") {
        return 'A';
    } else if (props.type == "consensus") {
        return 'C';
    } else if (props.type == "user") {
        return 'U';
    }
});

let message = computed(() => {
    if (props.type == "auto") {
        return props.vartype == 'SNV' ?
            translate('Pre-computed classification based on the CANDICE ACMG_SNV classifier') + '<br><i>' + translate('The class displayed depends on the settings') + '</i><br>' :
            translate('Pre-computed classification based on the CANDICE ACMG_CNV classifier') + '<br>' + translate('Score: :score', { score: props.score.toFixed(2) }) + '<br>';
    } else if (props.type == "consensus") {
        return translate('Consensus classification') + '<br>';
    } else if (props.type == "user") {
        return translate('This :vtype has been classified by you or your team :n time(s) as ', { vtype: props.vartype, n: props.count }) + '<br>';
    } else {
        return '';
    }
});
</script>

<style scoped></style>
